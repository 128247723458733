/*///////////////////////////////*/
/*///////  Bindings     //////*/
/*///////////////////////////////*/



$(window).scrollEnd(function () {
    if ($('.subpages_list').length > 0 && active_third_layer_link_bool === true && check_scroll_end === true) {
        window.location.hash = active_third_layer_link;
    }
}, 100);


$(window).scroll(function () {
    if (!$('html').hasClass('cbs_mobile')) {
        check_submenu_list_items();
    }
});


// vc acc closing hack
$(document).on('mouseup', '.vc_tta-panel.vc_active', function (event) {
    var that = this;
    var body_el = $(that).find(".vc_tta-panel-body");
    body_el.css("max-height", body_el.height());
    body_el.css("min-height", "0");
    body_el.css("transition", "max-height .1s ease-in-out");
    
    setTimeout(function () {
        $(that).find(".vc_tta-panel-body").css("max-height", "0");
    }, 100);
    setTimeout(function () {
        $(that).find(".vc_tta-panel-body").css("display", "none");
    }, 200);
    setTimeout(function () {
        $(that).removeClass('vc_active');
    }, 300);

    event.stopImmediatePropagation();
    return false;
});


$('.pushevent_yes a').on('click', function (event) {
    $('.pushevent_yes').click();
    return false;
});

$('.pushevent_no a').on('click', function (event) {
    $('.pushevent_no').click();
    return false;
});


$(document).on('click', '.pushevent_yes:not(.clicked .pushevent_yes)', function () {
    if ($(this).parent('ul.survey').css('opacity') == 1) {

        $(this).parent('ul.survey').addClass('clicked').animate({opacity: 0}).children('li a');
        $('ul.survey li a').attr('tabindex', '-1');
        $('.response.positive').fadeIn();
    }
});


$(document).on('click', '.pushevent_no:not(.clicked .pushevent_no)', function () {
    if ($(this).parent('ul.survey').css('opacity') == 1) {

        $(this).parent('ul.survey').addClass('clicked').animate({opacity: 0}).children('li a');
        $('ul.survey li a').attr('tabindex', '-1');
        $('.response.negative').fadeIn();
    }
});

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

$(document).on('click', '[data-binding="login"]', function() {
    var active_acl_cookie = {};
    serializedCookie = readCookie("acl");
    if (serializedCookie) {
        var active_acl_cookie = JSON.parse(atob(decodeURIComponent(serializedCookie)));
    }

    switch($(this).attr('data-ref')) {

        case 'portal':

            if (active_acl_cookie && active_acl_cookie.bodyClass === 'patient') {
                window.location.href= '/login/';
                return false;
            }
            active_acl_cookie.form = "patient__form--serviceportal";
            active_acl_cookie.bodyClass = "patient";

            document.cookie = ("acl="+encodeURIComponent(btoa(JSON.stringify(active_acl_cookie)))+"; expires=Thu, 18 Dec 2999 12:00:00 UTC; path=/");
            window.location.href = '/login/';
            break;

        case 'fach':

            if (active_acl_cookie && active_acl_cookie.bodyClass === 'fachkreis') {
                window.location.href= '/login/';
                return false;
            }
            active_acl_cookie.form = "fachkreis__form--serviceportal";
            active_acl_cookie.bodyClass = "fachkreis";

            document.cookie = ("acl="+encodeURIComponent(btoa(JSON.stringify(active_acl_cookie)))+"; expires=Thu, 18 Dec 2999 12:00:00 UTC; path=/");
            window.location.href = '/login/';
            break;

        default:
            console.log('no known ref specified');
    }

    return false;

});


$(document).on('click', '.tools.print', function () {
    window.print();
    return false;
});


/***  Anchors   *******************************************/

$(document).on('click', 'article a:not(.wpb_accordion a, a[class*="internal"], a.no-anchor, .news_tag_tags a), a.link-parkinson-anzeichen', function (event) {

    if ($(this).is('[href*="#"]')) { // ext pages width hashes

        if ($(this).is('[href^="#"]')) { // internal hashes
            var internal_hash = $(this).attr('href').replace('#', '');

            if ($('a[id="' + internal_hash + '"]').length > 0) {
                $('html,body').animate({scrollTop: $('a[id="' + internal_hash + '"]').offset().top - 220}, 'slow');
                return false;
            }

            if ($('a[name="' + internal_hash + '"]').length > 0) {
                $('html,body').animate({scrollTop: $('a[name="' + internal_hash + '"]').offset().top - 220}, 'slow');
                return false;
            }

            return false;

        }

        var href = $(this).attr("href");
        var hash = href.substr(href.indexOf("#")).replace('#', '');
        $('html,body').animate({scrollTop: $('a[data-name="' + hash + '"]').offset().top - 110}, 'slow');
        return false;
    }

});

$(document).on('click', '.abbvieshared a.internal-link', function () {
    $('html,body').animate({scrollTop: $('div#' + $(this).attr('href').replace('#', '')).offset().top - 50}, 'slow');
    return false;
});


$(document).on('click', '.subpages_list li a, .grey-overlay .anchor-link', function (event) {
    //if(window.location.hash) {
    window.location.hash = $(this).attr('href').replace('#', '');
    load_hash($(this).attr('href').replace('#', ''));
    //}
    return false;
});


/* CMAG Anchor */

$('#form_mbm89g22 .form_close ').on('click', function (event) {
    event.preventDefault();
    $('#form_mbm89g22').slideUp();
    $('#form_mbm89g4').fadeIn();
    return false;
});

$('#form_mbm89g22 .form_close ').on('click', function (event) {
    $('#form_mbm89g22').slideUp();
    $('#form_mbm89g4').fadeIn();
    return false;
});


if (open_form) {
    $('#form_mbm89g22').hide();
    $('#form_mbm89g4').show();
    //$('html,body').scrollTop($('#form_mbm89g').offset().top-110);
}

$('#form_mbm89g4 a.abbestellen').on('click', function (event) {
    $('#form_mbm89g4').hide();
    $('#form_mbm89g22').fadeIn();
    $('html,body').animate({scrollTop: $('#form_mbm89g22').offset().top - 110}, 'slow');
    return false;
});

$('#form_mbm89g a:contains("Kündigung des Abos")').on('click', function (event) {
    event.preventDefault();
    $('#form_mbm89g22').hide();
    $('#form_mbm89g4').fadeIn();
    $('html,body').animate({scrollTop: $('#form_mbm89g4').offset().top - 110}, 'slow');
    return false;
});


/*** READSPEAKER *****************************************/

var rs_loaded = false;

$(document).on('click', '.nav-icon.icon-readspeaker, .overlay-mobile-menu .icon-readspeaker', function (event) {

    console.log('readspeaker pressed');

    if (!rs_loaded) {

        $('#readspeaker .rsbtn_play').attr(atob('aHJlZg=='), atob(rsl));

        async('f1-eu.readspeaker.com/script/7791/ReadSpeaker.js?pids=embhl', function () {
            rs_loaded = true;
            ReadSpeaker.init();

            //GTM Event
            var GTM_Data_buttons = {"event":"buttonClick", "buttonLabel":"Website vorlesen", "buttonName":"Read Button"};
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(GTM_Data_buttons);

            setTimeout(function () {
                $('.nav-container .readspeaker, .overlay-mobile-menu .icon-readspeaker').trigger('click');
                if ($('html').hasClass("mobileisopen")) {
                    toggle_mobile_menu();
                }
                
            }, 300);
        });

        return false;

    }

    //console.log('readspeakerbutton exec normal func');

    if ($(this).hasClass('play')) {

    } else {
        console.log("play class");
        $(this).toggleClass('play');

        $('#readspeaker').toggleClass('enable');

        $('.wpb_accordion_header').addClass('header-active ui-state-active');
        $('.wpb_accordion_content').addClass('ui-accordion-content-active').attr('aria-hidden', 'false').slideDown();
        var offset = 110;
        if ($(window).width() < 576) {
            offset = 35;
        }

        if ($('h2:eq(0)').length) {
            $('html,body').animate({scrollTop: $('h2:eq(0)').offset().top - offset}, 'slow'); // -$(".vc_submenu").height()},'slow');
        }
        else{
            $('html,body').animate({scrollTop: $('.page-content-wrapper:eq(0)').offset().top - offset}, 'slow'); // -$(".vc_submenu").height()},'slow');
        }
        

        $('#readspeaker a.rsbtn_play').click();
        $('#readspeaker .rsbtn_pause').focus();

        check_scroll_end = false;
        return false;

    }

});

$(document).on('mousedown', '.sidebar_item .rsbtn_closer', function (event) {
    event.preventDefault();
    $('.sidebar_item.readspeaker').removeClass('play');

    $('#readspeaker').toggleClass('enable');

});


/********************************************************/


$('.sidebar_item.news').on('click', function (event) {
    window.location.href = $(this).find('a').attr('href');
});




$(document).on('click', 'div.contrast', function (event) {
    $('div.contrast').toggleClass('set');
    if ($('div.contrast').hasClass("set")) {
        $('div.contrast span.screen-reader').html("Kontrast verringern");
        $('div.contrast .tooltip p').html("Kontrast verringern");
    }
    else{
        $('div.contrast span.screen-reader').html("Kontrast erhöhen");
        $('div.contrast .tooltip p').html("Kontrast erhöhen");
    }
    $('input').removeAttr('placeholder');

    $('html').toggleClass($(this).attr('data-name'));
    $(document.body).trigger("sticky_kit:recalc");
    if ($(this).hasClass('set')) {
        set_cookie($(this).attr('data-cookie'), 'true');
    } else {
        set_cookie($(this).attr('data-cookie'), 'false');
    }
    return false;
});


$(document).on('click', 'div.size', function (event) {
    $('div.size').toggleClass('set');
    if ($('div.size').hasClass("set")) {
        $('div.size span.screen-reader').html("Schriftgröße verringern");
        $('div.size .tooltip p').html("Schriftgröße verringern");
    }
    else{
        $('div.size span.screen-reader').html("Schriftgröße erhöhen");
        $('div.size .tooltip p').html("Schriftgröße erhöhen");
    }
    $('html').toggleClass($(this).attr('data-name'));
    $(document.body).trigger("sticky_kit:recalc");
    if ($(this).hasClass('set')) {
        set_cookie($(this).attr('data-cookie'), 'true');
    } else {
        set_cookie($(this).attr('data-cookie'), 'false');
    }
    return false;
});

// KEYS for access


$('.icon-hamburger, .add_menu').keypress(function (e) {


    if (e.which === 13) {

        aria_expand(true, '.icon-hamburger', '.nav-container');


        if ($('html').hasClass('mobileisopen')) {
        } else {
            $('.nav-container').css({top: $(window).scrollTop() + 'px'});
            // $('html').addClass('mobileisopen');
        }


        $(this).addClass('back_focus');
        $('.icon-hamburger').click();
        //$('.nav-container *').removeAttr('tabindex');
        $("#foo").unbind("click");

        mask_focus('.nav-container');

        $('.nav-container .nav .portal_link a, .nav-container .nav .contrast, .nav-container .nav .size, .nav-container .nav .search_btn, .close_mobile, .overlay-mobile-menu .dl-menu a').attr('tabindex', '0');

    }


});


$('div.contrast:eq(0)').keypress(function (e) {
    if (e.which === 13) {
        $('div.contrast:eq(0)').click();
    }
});

$('div.contrast:eq(1)').keypress(function (e) {
    if (e.which === 13) {
        $('div.contrast:eq(1)').click();
    }
});

$('div.size:eq(0)').keypress(function (e) {
    if (e.which === 13) {
        $('div.size:eq(0)').click();
    }
});

$('div.size:eq(1)').keypress(function (e) {
    if (e.which === 13) {
        $('div.size:eq(1)').click();
    }
});

$('div.search_btn:eq(0)').keypress(function (e) {
    if (e.which === 13) {
        $('div.search_btn:eq(0)').click();
        $(this).addClass('back_focus');
        mask_focus('.header_panel.searchform');
        $('.header_panel.searchform input[type="search"]').focus();
        aria_expand(true, '.search_btn', '.header_panel.searchform');
    }
});

$('div.search_btn:eq(1)').keypress(function (e) {
    if (e.which === 13) {
        $('div.search_btn:eq(1)').click();
    }
});

$('.sidebar_item.readspeaker').keydown(function (e) {
    if (e.which === 13) {
        $('.sidebar_item.readspeaker').click();
    }
});


$('.close_rs').keydown(function (e) {
    if (e.which === 13) {
        $('.close_rs').click();
    }
});


$('.close_hpanel').keypress(function (e) {
    if (e.which === 13) {
        $('li.search_btn:eq(0)').click();
        remask_focus();
    }
});

$('.top-nav .indikationen>a').keydown(function (e) {


    if (e.which === 13 && !$('html').hasClass('mobile')) {

        $(this).addClass('back_focus');
        check_mm_tab_position();
        $('.serviceprogramm-menu').removeClass('serviceprogramm-menu--show');
        $('.login-menu').removeClass('LoginMenu--show');
        $('.megamenu_container').addClass('show_mm');
        mask_focus('.megamenu_container');
        $('.megamenu_container .megamenu_uls div.acne-inversa a').focus();
        aria_expand(true, '.top-nav div.indikationen a', '.megamenu_container');

    }
});


$('.megamenu_container .megamenu_uls .col-2 li:last-of-type').keydown(function (e) {
    $('.megamenu_container').removeClass('show_mm');
    remask_focus();
});

$('.filter_container_ul .filter_ul li:last-of-type').keydown(function (e) {
    $('.filter_ul').removeClass('show_it');
    remask_focus('.filter_current');
    setTimeout(function () {
        $('.filter_current').focus();
    }, 200);

});







// $(document).on('click', '.pkm2, .sidebar_pkm2_teaser', function () {
//     $('head').append('<script async src="//players.brightcove.net/2157889324001/default_default/index.min.js"></script>');
//     $('.overlay_container.pk_movie2').addClass('opened');
//     $('.overlay_container.pk_movie2').fadeIn();
//     $('#myExperience5312135572001').attr('tabindex', '1');
//     $('.overlay_container.pk_movie2 .overlay').addClass('opened');

//     mask_focus('.overlay_container.pk_movie2');
//     return false;
// });




// $(document).on('click', '.vc_toolbar .mail', function () {

//     $(this).addClass('back_focus');

//     $('.overlay_container.mail').fadeIn();
//     $('.overlay_container.mail input#field_rs1tp7').attr('tabindex', '1');
//     //$('.overlay_container.mail .overlay').slideDown();
//     $('.overlay_container.mail .overlay').addClass('opened');

//     mask_focus('.overlay_container.mail');

//     return false;
// });


$(document).on('click', '.calendar__addShareButton--email', function () {

    $(this).addClass('back_focus');

    $('.overlay_container.share_event').fadeIn();
    $('.overlay_container.share_event input#field_rs1tp7').attr('tabindex', '1');
    //$('.overlay_container.share_event .overlay').slideDown();
    $('.overlay_container.share_event .overlay').addClass('opened');

    mask_focus('.overlay_container.share_event');

    $('#field_lxru8f2').val($(this).attr('data-event-url'));
    $('#field_96nlu').val($(this).attr('data-ics-url'));
    $('body').css('overflow', 'hidden');

    return false;
});


$(document).on('click', '.search_btn, .close_hpanel', function () {
    if (state != 'mobile') {
        if ($('.search_btn').hasClass('set')) {
            aria_expand(false, '.search_btn', '.header_panel.searchform');
            $('html').removeClass('searchboxexpand');
            $('body').removeClass('noscroll');
            $('body').css('overflow', 'auto');
            $('.header_panel.searchform').removeClass('no-move-left');
        } else {
            aria_expand(true, '.search_btn', '.header_panel.searchform');
            $('html').addClass('searchboxexpand');
            $('body').addClass('noscroll');
            $('body').css('overflow', 'hidden');
            if (!$('html').hasClass('mobileisopen')){
                $('.header_panel.searchform').addClass('no-move-left');
            }
        }
        $('.header_panel.searchform').slideToggle(200, 'easeOutQuad');
        $('.search_btn').toggleClass('set');
        $('.header_panel input[type="search"]').select().focus();
        return false;
    }
});



$(document).on('click', '.ac_img_lightbox:not(.overlay_container.ac_img_lightbox)', function (event) {

    $(this).addClass('back_focus');

    $('.overlay_container.ac_img_lightbox').fadeIn();
    $('.overlay_container.ac_img_lightbox .overlay').addClass('opened');
    //$('.overlay_container.ac_img_lightbox .overlay .overflow').attr('style','background-image: url('+$(this).attr('src')+') !important;');
    $('.overlay_container.ac_img_lightbox .overlay .overflow').html('').append('<img id="active_image" src="' + $(this).attr('src') + '" style="">');
    $('body').css('overflow', 'hidden');
    $(document).on('click', '.overlay_container:not(.overlay)', function () {

        $('.overlay_container').fadeOut('fast');
        $('.overlay').removeClass('opened');
        remask_focus();
        $('body').css('overflow', 'auto');
        return false;
    });
    
    mask_focus('.overlay_container.ac_img_lightbox');

});


/*///////////////////////////////*/


/*$('.vc_footer').html(function (i, html) {
    return html.replace(/(\|)/g, '<span class="divider">$1</span>');
});
*/

///////


//////     EXIT DISCLAIMBER for outgoing Links    ////////////////////////


triggerED = function(href,target) {

    $(this).addClass('back_focus');


    $('.overlay_container.exit_dis .overlay .link_a').attr('href', href);
    $('.overlay_container.exit_dis .overlay .link_span').html(href);
    $('.overlay_container.exit_dis').css('display', 'flex');
    $('.overlay_container.exit_dis .overlay').addClass('opened');
    $('body').css('overflow', 'hidden');

    mask_focus('.overlay_container.exit_dis');


};



$(document).on('click', 'a[href]', function () {

    var href = $(this).attr('href');
    var target = $(this).attr('target');

    if (isExitDisclaimberLink($(this))) {

        triggerED(href,target);
        return false;

    }


});


$(document).on("click", ".link_a:not(.overlay_container--privacy_asp .link_a)", function () {
    $('.overlay_container').fadeOut('fast');
    window.open($(this).attr('href'), '_blank');
});

$(document).on('click', '[data-binding="video_overlay"]', function(e) {
  e.preventDefault();
  open_dyn_video($(this).attr('data-type'), $(this).attr('data-id'),$(this).attr('data-account'),$(this).attr('data-code'),$(this).attr('data-ratio'),$(this).attr('data-title'));
});



/////////////////////////////////////////////////////////////////////////


// CLOSE OVERLAYS //// (X)


$(document).on('click', '.close_overlay, .x, .link_a, [data-binding*="_hint_close"]', function () {
    close_overlays();
    return false;
});


$(document).keyup(function (e) {
    if (e.keyCode === 27) { // esc keycode

        if ($('.search_btn').hasClass('set')) {
            aria_expand(false, '.search_btn', '.header_panel.searchform');
            $('html').removeClass('searchboxexpand');
            $('body').removeClass('noscroll');
            $('.search_btn').removeClass('set')
            $('.header_panel.searchform').removeClass('no-move-left');
        }

        if ($('.megamenu_container').hasClass('show_mm')) {
            $('.megamenu_container').removeClass('show_mm');
            remask_focus();
        }

        if ($('.filter_ul').hasClass('show_it')) {
            $('.filter_ul').removeClass('show_it');
            remask_focus('.filter_current');
            setTimeout(function () {
                $('.filter_current').focus();
            }, 200);
        }

        if ($('.header_panel.searchform').css('display') === 'block') {
            $('.header_panel.searchform').slideUp();
            remask_focus();
        }


        close_overlays();
        //console.log('esc on document pressed (overlay)');


        if ($('html').hasClass('mobileisopen')) {
            $('.icon-hamburger').click();
            //console.log('esc on document pressed (icon-hamburger)');
            remask_focus();
            $('.nav-container *').attr('tabindex', '-1');
            //$('.nav-container *').unbind('keypress');
        }


    }
});


/// OPEN Related Posts


$(document).on('click', '.related_posts', function () {
    $(this).addClass('back_focus');
    $('.overlay_container.related').fadeIn();
    $('.overlay_container.related .overlay').addClass('opened');
    mask_focus('.overlay_container.related');
    return false;
});


if ($('body').hasClass('postid-4504')) {
    $('.ced-app-teaser-news').addClass('strict_green_background').show();
}


// Indikationen (Front & Footer) > Circle act like links



$('.top a, .circle_up').keypress(function (e) {
    if (e.which === 13) {
        $('.logo-container a').focus();
        $("html, body").animate({scrollTop: 0}, "slow");
    }
});


// AJAX article imgs


$(document).on('click', '.asilo_link', function (event) {
    asilo_link($(this).attr('data-href'));
});


// Header Intel //


//Downloads:


$('.dl-item').not('.no-link .dl-item, .dl-item a, .calendar__links .dl-item, .dl-item .dl-share').on('click', function (event) {
    event.preventDefault();
    
    exec_prg($(this).find('.dl-file').attr('ml'),$(this).find('.dl-file').attr('mlt'));
    return true;
});

$(document).on('click', '.calendar__links .dl-item:not(.calendar__links .dl-items a)', function (event) {
    $(this).find('a').click();
    return false;
});

$('.dl-item .dl-further-link').on('click', function (event) {
    event.stopPropagation();

    var GTM_Data_buttons = { "event": "buttonClick" };
    GTM_Data_buttons["buttonName"] = "Barrierefrei Button";
    GTM_Data_buttons["buttonLabel"] = "Online-Inhalt barrierefrei";
    GTM_Data_buttons["buttonContext"] = jQuery(this).parent().parent().parent().parent().parent().parent().find("button.accordion h2").text() + " - " + jQuery(this).parent().find(".dl-file").text();
    publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_buttons)));

    window.open($(this).attr("href"), '_blank').focus();
    
});




$('.dl-item .indication_link').on('click', function (event) {

    window.location.href = $(this).attr("href");
    event.stopPropagation();

});

$('.dl-item .dl-share').on('click', function (event) {

    window.location.href = "mailto:?subject=Empfohlenes Dokument auf AbbVie Care: " + $(this).parent().parent().find('.dl-file').html() + "&body=" + atob($(this).parent().parent().find('.dl-file').attr('ml'));
    event.stopPropagation();
    $(this).addClass('back_focus');


    // event.stopPropagation();
    // $(this).addClass('back_focus');

    // $('.overlay_container.share-dl-mail').fadeIn();
    // $('.overlay_container.share-dl-mail input#field_rs1tp73').attr('tabindex', '1');
    // $('.overlay_container.share-dl-mail .overlay').addClass('opened');
    // //set doc name
    // $('.overlay_container.share-dl-mail input#field_ezj3k').val($(this).parent().parent().find('.dl-file').html());
    // //set doc url
    // $('.overlay_container.share-dl-mail input#field_a81s').val(atob($(this).parent().parent().find('.dl-file').attr('ml')));

    // $('.dl-file').removeClass("active");
    // $(this).parent().parent().find('.dl-file').addClass("active");

    // mask_focus('.overlay_container.share-dl-mail');

    // return false;
});

$('.dl-share-standalone').on('click', function (event) {

    window.location.href = "mailto:?subject=Empfohlenes Dokument auf AbbVie Care: " + $(this).attr('data-title') + "&body=" + $(this).attr('data-url');

    event.stopPropagation();
    $(this).addClass('back_focus');

    // $('.overlay_container.share-dl-mail').fadeIn();
    // $('.overlay_container.share-dl-mail input#field_rs1tp73').attr('tabindex', '1');
    // $('.overlay_container.share-dl-mail .overlay').addClass('opened');
    // //set doc name
    // $('.overlay_container.share-dl-mail input#field_ezj3k').val($(this).attr('data-title'));
    // //set doc url
    // $('.overlay_container.share-dl-mail input#field_a81s').val($(this).attr('data-url'));

    // $('.dl-file').removeClass("active");
    // $(this).parent().parent().find('.dl-file').addClass("active");

    // mask_focus('.overlay_container.share-dl-mail');

    // return false;
});

$('.share-dl-mail .close_overlay').on('click', function (event) {
    $('.frm_message').css('display','none');
});

$(document).on('click', '#form_ojlrsl3 .frm_final_submit', function (event) {
   //set doc name after submit to enable a second submit
   $('.overlay_container.share-dl-mail input#field_ezj3k').val($('.dl-file.active').html());
   //set doc url
   $('.overlay_container.share-dl-mail input#field_a81s').val(atob($('.dl-file.active').attr('ml')));
});



$('.dl-container-wrapper .dl-container:not(.active)').hide();


$('.category_switch .category').on('mousedown', function (event) {
    dlToogleTab($(this));
    event.preventDefault();
});


// FOCUS //

$(document).on('mousedown', 'html', function (e) {
    $('html').removeClass('access');
});

$(document).on('focusout', '#abvSearchField', function (e) {
    
    setTimeout(function () {
        $('.suggestions-wrapper').removeClass('visible');
        $('#abvSearchField').removeClass('hasfocus');
        
    }, 150);
});
$(document).on('focus', '#abvSearchField', function (e) {
    $('.suggestions-wrapper').addClass('visible');
    $('#abvSearchField').addClass('hasfocus');
});

$(document).keydown(function (e) {
    var code = e.keyCode || e.which;
    //console.log($(document.activeElement).attr('class'));
    if (code === 9) {
        $('html').addClass('access');

        //$('.post-type-archive-news .more-link').attr('tabindex','-1');
        //$('.post-type-archive-news [itemprop="articleBody"] :header').attr('tabindex','0');

        //check_search_fixed();

        if ($('.vc_submenu').css('top') === '0px') {
            if (!submenu_is_stuck) {
                $('.perm:eq(1) li').attr('tabindex', '0');
                $('.vc_submenu').addClass('should_be_stuck');
                submenu_is_stuck = true;
                $('.header_panel.searchform').addClass('should_be_stuck');
            }
        } else {
            if (submenu_is_stuck) {
                $('.perm:eq(1) li').attr('tabindex', '-1');
                $('.vc_submenu').removeClass('should_be_stuck');
                submenu_is_stuck = false;
                $('.header_panel.searchform').removeClass('should_be_stuck');
            }
        }


    }
});


$('.subpages_list a').keydown(function (e) {
    var code = e.keyCode || e.which;
    if (code === 9) {
        $(this).focus();
    }
});


/******* News FILTER ********/


$('.filter_current').on('click', function (event) {

    $(this).parent().find(".filter_container_ul").css('z-index', '99999999');
    $(this).parent().find('.filter_ul').addClass('show_it');
    $(this).parent().find('.filter_ul').css("top", "-"+$(this).css("height"));
    aria_expand(true, $(this), $(this).parent().find(".filter_container_ul"));
    event.preventDefault();
    event.stopPropagation();

});


$('.filter_current').on('keypress', function (e) {
    if (e.which === 13) {
        $(this).parent().find(".filter_container_ul").css('z-index', '99999999');
        $(this).parent().find('.filter_ul').addClass('show_it');
        aria_expand(true, $(this), $(this).parent().find(".filter_container_ul"));
        mask_focus($(this).parent().find(".filter_container_ul"));
    }
});


$('.filter_ul').on('mouseleave', function (event) {
    var el = $(this);
    event.preventDefault();
    el.parent().find('.filter_container_ul').css('z-index', '99999');
    el.removeClass('show_it');
    aria_expand(false, el.parent().parent().find('.filter_current'), el);
});


/****************************/


/* Delete Cateogry bei DL exts*/

$('.dl-container-wrapper').each(function (index, el) {
    //console.log('download field '+index+'>>>> '+$(this).find('.dl-container').length);
    if ($(this).find('.dl-container').length === '2') {
        $(this).find('.category:eq(1)').detach();
    }
});


/***  Megamenu  **/





$('.top-nav div.indikationen').on('mouseover', function (event) {
    check_mm_tab_position();
    $('.serviceprogramm-menu').removeClass('serviceprogramm-menu--show');
    $('.login-menu').removeClass('LoginMenu--show');
    $('.megamenu_container').addClass('show_mm');
    aria_expand(true, '.top-nav div.indikationen a', '.megamenu_container');
    return false;
});

$('.megamenu_container').on('mouseleave', function (event) {
    //event.preventDefault();
    $('.megamenu_container').removeClass('show_mm');
    aria_expand(false, '.top-nav div.indikationen a', '.megamenu_container');
    return false;
});


/********/


$(document).on('mouseenter', '.nav-icons .nav-icon', function () {
    $(this).addClass('tooltip--show');
});

$(document).on('mouseleave', '.nav-icons .nav-icon', function () {
    $(this).removeClass('tooltip--show');
});


// BITV

$('.vc_body').attr('role', 'main');
$('.page-id-12 [itemprop="articleBody"]').attr('role', 'main');


// Tab Panels


$('.category_switch a').each(function (index, el) {
    $(this).attr({
        'role': 'button',
        'aria-selected': (index === 0 ? 'true' : 'false'),
        'id': 'tab' + (index + 1)
    });
});

$('.dl-container:not(.calendar__links, .item-teaser)').each(function (index, el) {
    $(this).attr({
        'aria-hidden': (index === 0 ? 'false' : 'true'),
        'aria-labelledby': 'tab' + (index + 1)
    });
});


$(document).on('keydown', '.category_switch a', function (e) {
    if (e.which === 13) {
        dlToogleTab($(this).closest('.category'));
        setTimeout(function () {
            if ($('.dl-container.active a').length < 1) {
                $('.dl-container.active').focus();
            } else {
                $('.dl-container.active a:first').focus();
            }
        }, 200);
    }
});


$('.post-type-archive article section h2, .page-parent article section h2').attr('tabindex', '0');


$(document).on('keydown', 'aside nav a', function (e) {
    if (e.which === 13) {
        var eq = $('aside nav a').index(this);
        $('html,body').animate({scrollTop: parseInt($('article section:eq(' + $('aside nav a').index(this) + ')').offset().top - 150)}, 200);
        setTimeout(function () {
            $('article section:eq(' + eq + ') h2:first').focus();
        }, 500);
        return false;
    }
});


// Frontpage News

$(document).on('click', '.frontPageNews__row .vc_column_container', function (el) {
    if ($(el.target).hasClass('frontPageNews__tag')) {
        window.location.href = '/news/' + '#' + $(el.target).parents('[data-topic]').attr('data-topic');
    } else {
        window.location.href = $(this).find('a').attr('href');
    }
});

$(document).on('click', '.frontPageNews__readmore', function () {
    $('.frontPageNews__row--3').slideDown();
    //$('html,body').animate({scrollTop: parseInt($('.frontPageNews__row--3').offset().top)},200);
    $(this).hide();
});

$(document).on('click', '.newsTopics__a', function (event) {
    event.preventDefault();
    event.stopPropagation();
    $('body').addClass('newsTopics__ul--show');
    
});

$(document).on('touchstart mouseenter', 'body.newsTopics__ul--show', function (e) {
    if ($('body').hasClass('newsTopics__ul--show') && !$(".newsTopics__ul").is(e.target) && !$(".ll_link").is(e.target) && !$(".newsTopics__li").is(e.target) && !$(".filter_container_ul").is(e.target) && !$(".ll_link").is(e.target) && !$(".filter_ul").is(e.target) && !$(".filter").is(e.target)) {
        $('body').removeClass('newsTopics__ul--show');
    }
});

$(document).on('touchstart mouseenter', 'body', function (e) {
    if ($('.filter_ul').hasClass('show_it') && !$(".filter_container_ul").is(e.target) && !$(".ll_link").is(e.target) && !$(".filter_ul").is(e.target) && !$(".filter").is(e.target) && !$(".filter a").is(e.target) && !$(".zabuto_calendar").is(e.target) && !$(".filter_ul table").is(e.target) && !$(".day").is(e.target) && !$(".calendar-month-navigation").is(e.target)  && !$(".calendar-month-navigation i").is(e.target)  && !$(".calendar-month-navigation span").is(e.target) && !$(".dow-clickable").is(e.target) && !$(".day span").is(e.target) && !$(".calendar_event_point_span_wrapper").is(e.target)&& !$(".calendar_event_point_span_wrapper span").is(e.target) ) {
        $('.filter_ul').removeClass('show_it');
    }
});

$(document).on('click', '.day', function (e) {
    var el = $(this).closest(".filter_ul");
    setTimeout(function () {
        el.removeClass('show_it');
    }, 200);
});


$(document).on('mouseleave', '.newsTopics__ul', function () {
    $('body').removeClass('newsTopics__ul--show');
});

$(document).on('mouseenter', '.icon-login', function () {
    $('.serviceprogramm-menu').removeClass('serviceprogramm-menu--show');
    $('.megamenu_container').removeClass('show_mm');
    $('.login-menu').addClass('loginMenu--show');
    event.preventDefault();
});

$(document).on('mouseleave', '.login-menu', function () {
    $('.login-menu').removeClass('loginMenu--show');
});
$(document).on('mouseenter', '.serviceprogramm', function () {
    $('.megamenu_container').removeClass('show_mm');
    $('.login-menu').removeClass('loginMenu--show');
    $('.serviceprogramm-menu').addClass('serviceprogramm-menu--show');
    event.preventDefault();
});

$(document).on('mouseleave', '.serviceprogramm-menu', function () {
    $('.serviceprogramm-menu').removeClass('serviceprogramm-menu--show');
});



// $(window).scroll(function (event) {
//     check_search_fixed();
//     if ($('.vc_submenu').css('top') === '0px') {
//         if (!submenu_is_stuck) {
//             $('.perm:eq(1) li').attr('tabindex', '0');
//             $('.vc_submenu').addClass('should_be_stuck');
//             submenu_is_stuck = true;
//             $('.header_panel.searchform').addClass('should_be_stuck');
//         }
//     } else {
//         if (submenu_is_stuck) {
//             $('.perm:eq(1) li').attr('tabindex', '-1');
//             $('.vc_submenu').removeClass('should_be_stuck');
//             submenu_is_stuck = false;
//             $('.header_panel.searchform').removeClass('should_be_stuck');
//         }
//     }
// });


$(window).on('resize load', function () {
    check_width();
});

$(window).on('load', function () {
    //$('#dl-menu').dlmenu({animationClasses: {classin: 'dl-animate-in-2', classout: 'dl-animate-out-2'}});
    //$('.dl-trigger').hide().click();

    mobile_is_set = true;
    modified_theader = true;
    show_back_to_top_arrow();
   
    if ($(window).width() < 695 && $('.current_page_item').length && $('.indikations_submenu').length) {
        $('.submenu_ul').animate({scrollLeft: $('li.current_page_item').offset().left-30}, 'fast');
    }
    
});






$(window).on("orientationchange", function (event) {
    check_width();
    $(document.body).trigger("sticky_kit:recalc");
});

$(document).on('click', '.divider_tags_more', function (e) {
    $(this).parent().find("li").show();
    $(this).parent().find(".divider_tags_more").removeClass('is_visible');
});

$(document).on('click touchend', '.dl-submenu.lastlevel li:not(li.dl-back) a', function () {
    var href = $(this).attr('href');
    var hash = href.substr(href.indexOf("#")).replace('#', '');
    window.location=href;
    toggle_mobile_menu();
    $('html,body').animate({scrollTop: $('a[data-name="' + hash + '"]').offset().top}, 'slow');
});

$(document).on('click', '.overlay-mobile-menu .close_mobile, .nav-container .icon-hamburger', function () {
    toggle_mobile_menu();
});
$(document).on('click', '.nav-container .icon-search, .abvSearchNewSearchRow .search_btn', function () {
    toggle_mobile_menu();
    setTimeout(function () {
        $('#mobileSearch').focus();
    }, 100);
});





$(document).on('keypress', '.overlay-mobile-menu .close_mobile', function (e) {
    if (e.which === 13) {
        remask_focus();
        toggle_mobile_menu();
    }
});

// Tags Minimizer

if ($('body').attr('class').match(/veranstaltungen|news|tax-cal_indikationen|tax-news_indikationen/)) {

    $(document).on('click', '.divider_tags_more', function () {
        var parent = $(this).parent('ul');
        parent.addClass('dont_touch_this');
        parent.find('li').show();
        $(this).hide();
        return false;
    });
}


// Calendar Reset Date Filter


$(document).on('click', '.calendar__filter_reset', function () {
    $('.preloader_container').fadeIn();

    $('.calendar_the_content_wrapper').load('/veranstaltungen/' + ' article#read', function (response, status, xhr) {
        if (status == "success") {
            $('.preloader_container').fadeOut();
            $('article#read').animate({opacity: 1});
        }
    });
    return false;
});


$(document).on('click', '.cal_excerpt_more, .cal_acc_arrow', function () {

    var id = $(this).attr('data-id');

    if ($('.calendar__acc_wrapper[data-id="' + id + '"]').attr('data-state') == 'closed') {
        $('.calendar__acc_wrapper[data-id="' + id + '"]').attr('data-state', 'open');
        $('.cal_excerpt_more[data-id="' + id + '"]').text('Weniger');
    } else {
        $('.calendar__acc_wrapper[data-id="' + id + '"]').attr('data-state', 'closed');
        $('.cal_excerpt_more[data-id="' + id + '"]').text('Mehr erfahren');
    }


});

$(document).on('click','.ac_checklist__question', function() {

  if($(this).attr('data-value') === '1') {
    $(this).attr('data-value', '0');
  } else {
    $(this).attr('data-value', '1');
  }

});

$(document).on('click','.ac_questionnaire__rb', function() {

  $(this).parents('.ac_questionnaire__rbs').find('.ac_questionnaire__rb').attr('data-value', '0');
  $(this).attr('data-value', '1');

});

$(document).on('click','[data-binding="ac_checklist_submit"]', function() {

  var items = $(this).parent('div').find('.ac_checklist > .ac_checklist__question');
  var data = { 'results':[] };

  items.each(function(index, el) {
    data.results.push(parseInt($(el).attr('data-value')));
  });

  window.location.href = '/wp-json/ac/public/getParkinsonChecklist1/?data=' + encodeURIComponent(JSON.stringify(data));

});

$(document).on('click','[data-binding="ac_questionnaire_submit"]', function() {

  var items = $(this).parent('div').find('.ac_questionnaire > .ac_questionnaire__item');
  var data = { 'results':[] };
  var validationSuccess = true;

  items.each(function(index, el) {

    if(!$(el).find('[data-value="1"]').length) {
      validationSuccess = false;
    } else {
      data.results.push($(el).find('[data-value="1"]').attr('data-realvalue'));
    }

  });

  if(validationSuccess) {
    window.location.href = '/wp-json/ac/public/getParkinsonChecklist2/?data=' + encodeURIComponent(JSON.stringify(data));
  } else {
    alert('Bitte füllen Sie alle Felder aus, vielen Dank.');
  }

});

$(document).on('click','[data-binding="ac_migraene_selbsttest_submit"]', function() {
    var answers = {};
    var counter = 1;
    $(".quiz-question .radio:checked").each(function () {
      answers["q"+counter] = parseInt($(this).val());
      //console.log("q"+counter +" " +$(this).val());
      counter++;
    });
      if (Object.keys(answers).length == 7) {
        if (answers.q1 == 0 && answers.q2 == 0 && answers.q3 == 0 && answers.q4 == 0 && answers.q5 == 0 && answers.q6 == 0 && answers.q7 == 0) {
            $(".result-content").attr("data-stage", "1")
            //GTM_Data_forms.formResult = "Keine Migraene";
            $(".result-headline").html("KEINE MIGRÄNE");
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/CM_Test_Auswertung-1.png" alt="">');
            $(".result-content").html("Der vorliegende Test deutet nicht darauf hin, dass Sie Chronische Migräne haben. Allerdings werden Kopfschmerzen von jedem Menschen anders wahrgenommen, sodass die Ursache nicht immer gleich erkannt wird. Da nicht alle Menschen mit Migräne oder Chronischer Migräne die gleichen Symptome haben, sind jedem Selbsttest Grenzen gesetzt. Bitte besuchen Sie einen Arzt, wenn Sie häufig unter Kopfschmerzen leiden bzw. die Schmerzen Sie beeinträchtigen.");
        }
        else if (answers.q1 + answers.q2 + answers.q3 + answers.q4 + answers.q5 <= 2 && answers.q1 == 0 && answers.q6 + answers.q7 == 0) {
            $(".result-content").attr("data-stage", "1")
            //GTM_Data_forms.formResult = "Keine Migraene";
            $(".result-headline").html("KEINE MIGRÄNE");
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/CM_Test_Auswertung-1.png" alt="">');
            $(".result-content").html("Möglicherweise ist eine Migräne Ursache Ihrer Beschwerden. Bitte lassen Sie diesen Verdacht von einem Arzt abklären.");
        }
        else if (answers.q1 + answers.q2 + answers.q3 + answers.q4 + answers.q5 >= 3 && answers.q6 + answers.q7 == 0) {
            $(".result-content").attr("data-stage", "2")
            //GTM_Data_forms.formResult = "Wahrscheinlich Migraene";
            $(".result-headline").html("WAHRSCHEINLICH MIGRÄNE");
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/CM_Test_Auswertung-2.png" alt="">');
            $(".result-content").html("Ihre Antworten deuten darauf hin, dass Sie Migräne haben. Bitte lassen Sie diesen Verdacht von einem Arzt abklären.");
        }
        else if (answers.q1 + answers.q2 + answers.q3 + answers.q4 + answers.q5 == 0 && answers.q6 == 1 && answers.q7 == 0) {
            $(".result-content").attr("data-stage", "2")
            //GTM_Data_forms.formResult = "Migraene und haezfige Kopfschmerzen";
            $(".result-headline").html("MIGRÄNE UND HÄUFIGE KOPFSCHMERZEN");
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/CM_Test_Auswertung-2.png" alt="">');
            $(".result-content").html("Der vorliegende Test deutet nicht darauf hin, dass Sie Chronische Migräne haben. Allerdings werden Kopfschmerzen von jedem Menschen anders wahrgenommen, sodass die Ursache nicht immer gleich erkannt wird. Da nicht alle Menschen mit Migräne oder Chronischer Migräne die gleichen Symptome haben, sind jedem Selbsttest Grenzen gesetzt. Wenn Sie regelmäßig Kopfschmerzen haben, kann dahinter eine Erkrankung stecken, die von einem Arzt abgeklärt und gegebenenfalls gezielt behandelt werden sollte. Zwar gibt es eine Vielzahl freiverkäuflicher Schmerzmittel in der Apotheke, doch sind sie nicht bei jeder der mehr als 200 heute bekannten Arten von Kopfschmerzen die richtige Wahl. Man kann - obwohl es unlogisch erscheint - sogar Kopfschmerzen bekommen, wenn man zu oft Schmerzmittel nimmt. Bitte besuchen Sie einen Arzt, wenn Sie häufig unter Kopfschmerzen leiden bzw. die Schmerzen Sie beeinträchtigen.");
        }
        else if (answers.q1 + answers.q2 + answers.q3 + answers.q4 + answers.q5 >= 1 && answers.q6 == 1 && answers.q7 == 0) {
            $(".result-content").attr("data-stage", "2")
            //GTM_Data_forms.formResult = "Wahrscheinlich Migraene";
            $(".result-headline").html("WAHRSCHEINLICH MIGRÄNE");
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/CM_Test_Auswertung-2.png" alt="">');
            $(".result-content").html("Eine Migräne oder eine andere behandlungsbedürftige Kopfschmerzerkrankung kann Ursache Ihrer Beschwerden sein. Bitte lassen Sie diesen Verdacht von einem Arzt überprüfen.");
        }
        else if ((answers.q6 == 1 && answers.q7 == 1) || answers.q7 == 1) {
            $(".result-content").attr("data-stage", "3")
            //GTM_Data_forms.formResult = "Chronische Migraene";
            $(".result-headline").html("CHRONISCHE MIGRÄNE");
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/CM_Test_Auswertung-3.png" alt="">');
            $(".result-content").html("Hinter Ihren Beschwerden steckt möglicherweise eine Chronische Migräne. Bitte lassen Sie diesen Verdacht von einem Arzt abklären.");
        }
        $('[data-binding="ac_migraene_selbsttest_print"]').css("display", "inline-block");
        $("#quiz-form").fadeOut();
        if ($(window).width() < 769) {
            var offset = 110;
        }
        else{
            var offset = 220;
        }
        $('html,body').animate({scrollTop: $('[name="selbsttest-anchor"]').offset().top - offset}, 'slow');

        //GTM Form Tracking
        // var GTM_Data_forms = { "event": "formSubmit", "formName":"Chronische Migraene Selbsttest Form"};
        // GTM_Data_forms.formStep = "Form Submit";
        // GTM_Data_forms.formStepNr = "2";
        // GTM_Data_forms.formStepCount = "3";
        // publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
        // console.log("formSubmit tracked for: " + GTM_Data_forms.formResult);
    }
    else{
        $('.error-message').fadeIn();
    }
});

$(document).on('click','[data-binding="ac_migraene_selbsttest_print"]', function() {

    var data = {info: {}, questions: {}};
    var counter = 0;
    data["info"]["result-text"] = $(".result-content").html();
    data["info"]["stage"] = $(".result-content").attr("data-stage");

    $(".quiz-question").each(function () {
        data["questions"]["q"+counter] = {};
        data["questions"]["q"+counter]["question"] = $(this).find(".quiz-question-headline").html();
        data["questions"]["q"+counter]["answer"] = $(this).find("input:checked+label").html();
        counter++;
    });
        
    //GTM Form Tracking
    // var GTM_Data_forms = { "event": "formPrint", "formName":"Chronische Migraene Selbsttest Form"};
    // GTM_Data_forms.formStep = "Form Print";
    // GTM_Data_forms.formStepNr = "2";
    // GTM_Data_forms.formStepCount = "3";
    // publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
    // console.log("formSubmit tracked for: " + GTM_Data_forms.formResult);
  
    window.location.href = '/wp-json/ac/public/getCMPrint/?data=' + encodeURIComponent(JSON.stringify(data));
  
 });


$(document).on('click','[data-binding="ac_harndrang_selbsttest_submit"]', function() {
    var answers = {};
    var counter = 1;
    $(".quiz-question [type='radio']:checked").each(function () {
      answers["q"+counter] = parseInt($(this).val());
      //console.log("q"+counter +" " +$(this).val());
      counter++;
    });
      if (Object.keys(answers).length == 8) {
        $(".result-headline").html("Ergebnis");
        if (answers.q8 == 0 || $("#q44").is(':checked')) {
           if (answers.q4 + answers.q5 + answers.q6 > 1) {
            $(".result-content").attr("data-stage", "3")
            //GTM_Data_forms.formResult = "stage3";
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/UB_Test_Auswertung-3.png" alt="">');
            $(".result-content").html("<strong>Ihre Symptome belasten Sie sehr:</strong><br>Ihre überaktive Blase scheint Ihr Leben zu bestimmen und Sie einzuschränken. Scheuen Sie sich nicht, mit Ihrem Arzt über Ihre Symptome und Sorgen zu sprechen - er hat dieses Gespräch bestimmt schon öfter geführt. Es gibt also keinen Grund, sich zu schämen.<br><br>Erwähnen Sie auch eventuelle Nebenwirkungen Ihrer Medikamente. Je mehr Ihr Arzt über Sie und Ihre Erkrankung weiß, desto besser kann er Ihnen helfen. Möglicherweise kann er Ihnen eine andere Behandlungsmöglichkeit für Ihre überaktive Blase empfehlen.");
           }
           else if (answers.q4 + answers.q5 + answers.q6 == 1) {
            $(".result-content").attr("data-stage", "2")
            //GTM_Data_forms.formResult = "stage2";
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/UB_Test_Auswertung-2.png" alt="">');
            $(".result-content").html("<strong>Sie haben Ihre Symptome ganz gut im Griff, es könnte aber besser sein. </strong><br><br>Auch wenn Ihre Behandlung recht erfolgreich ist: Besprechen Sie Ihre Symptome mit Ihrem Arzt und überlegen Sie gemeinsam, ob es weitere Behandlungsoptionen für Ihre überaktive Blase gibt. Denken Sie daran: Es gibt keinen Grund, sich zu schämen. Erwähnen Sie im Gespräch auch eventuelle Nebenwirkungen Ihrer Medikamente. Je mehr Ihr Arzt über Sie und Ihre Erkrankung weiß, desto besser kann er Ihnen helfen.");
           }
           else if (answers.q4 + answers.q5 + answers.q6 == 0) {
            $(".result-content").attr("data-stage", "1")
            //GTM_Data_forms.formResult = "stage1";
            $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/UB_Test_Auswertung-1.png" alt="">');
            $(".result-content").html("<strong>Sie scheinen Ihre überaktive Blase unter Kontrolle zu haben. Super! </strong><br><br>Denken Sie daran, mit Ihrem Arzt zu sprechen, falls sich etwas verschlechtern sollte oder Sie Ihre Medikamente nicht mehr vertragen.");
           }
        }
        else if (answers.q8 == 1 && !$("#q44").is(':checked')) {
            if (answers.q4 + answers.q5 + answers.q6 == 0 && ($("#q51").is(':checked') || $("#q61").is(':checked') || $("#q71").is(':checked'))) {
                $(".result-content").attr("data-stage", "2")
                //GTM_Data_forms.formResult = "stage2";
                $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/UB_Test_Auswertung-2.png" alt="">');
                $(".result-content").html("Sie haben Ihre Symptome ganz gut im Griff, es könnte aber besser sein. <br><br>Auch wenn Ihre Behandlung recht erfolgreich ist: Besprechen Sie Ihre Symptome mit Ihrem Arzt und überlegen Sie gemeinsam, ob es weitere Behandlungsoptionen für Ihre überaktive Blase gibt. Denken Sie daran: Es gibt keinen Grund, sich zu schämen. Erwähnen Sie im Gespräch auch eventuelle Nebenwirkungen Ihrer Medikamente. Je mehr Ihr Arzt über Sie und Ihre Erkrankung weiß, desto besser kann er Ihnen helfen.");
            }
            else if (answers.q4 + answers.q5 + answers.q6 == 0 && !$("#q51").is(':checked') && !$("#q61").is(':checked') && !$("#q71").is(':checked')) {
                $(".result-content").attr("data-stage", "2")
                //GTM_Data_forms.formResult = "stage1";
                $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/UB_Test_Auswertung-1.png" alt="">');
                $(".result-content").html("Sie scheinen Ihre überaktive Blase unter Kontrolle zu haben. Super! <br><br>Denken Sie daran, mit Ihrem Arzt zu sprechen, falls sich etwas verschlechtern sollte oder Sie Ihre Medikamente nicht mehr vertragen.");
            }
            else {
                $(".result-content").attr("data-stage", "3")
                //GTM_Data_forms.formResult = "stage3";
                $(".quiz-pre-content").html('<img src="/content/themes/roots-sass-master/dist/images/UB_Test_Auswertung-3.png" alt="">');
                $(".result-content").html("<strong>Ihre Symptome belasten Sie sehr: </strong><br>Ihre überaktive Blase scheint Ihr Leben zu bestimmen und Sie einzuschränken. Scheuen Sie sich nicht, mit Ihrem Arzt über Ihre Symptome und Sorgen zu sprechen - er hat dieses Gespräch bestimmt schon öfter geführt. Es gibt also keinen Grund, sich zu schämen.<br><br>Erwähnen Sie auch eventuelle Nebenwirkungen Ihrer Medikamente. Je mehr Ihr Arzt über Sie und Ihre Erkrankung weiß, desto besser kann er Ihnen helfen. Möglicherweise kann er Ihnen eine andere Behandlungsmöglichkeit für Ihre überaktive Blase empfehlen.");
            }
        }

        $("#quiz-form").fadeOut();
        if ($(window).width() < 769) {
            var offset = 110;
        }
        else{
            var offset = 220;
        }
        $('html,body').animate({scrollTop: $('[name="selbsttest-anchor"]').offset().top - offset}, 'slow');
        $('[data-binding="ac_harndrang_selbsttest_print"]').css("display", "inline-block");
        //GTM Form Tracking
        // var GTM_Data_forms = { "event": "formSubmit", "formName":"Harndrang Selbsttest Form"};
        // GTM_Data_forms.formStep = "Form Submit";
        // GTM_Data_forms.formStepNr = "2";
        // GTM_Data_forms.formStepCount = "3";
        // publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
        // console.log("formSubmit tracked for: " + GTM_Data_forms.formResult);

        
    }
    else{
        $('.error-message').fadeIn();
    }
  
});

$(document).on('click','[data-binding="ac_harndrang_selbsttest_print"]', function() {

    var data = {info: {}, questions: {}};
    var counter = 0;
    var answer_counter = 0;
    data["info"]["result-text"] = $(".result-content").html();
    data["info"]["stage"] = $(".result-content").attr("data-stage");

    $(".quiz-question").each(function () {
        data["questions"]["q"+counter] = {};
        data["questions"]["q"+counter]["answers"] = {};
        data["questions"]["q"+counter]["question"] = $(this).find(".quiz-question-headline").html();
        answer_counter = 0;
        $(this).find("input:checked+label").each(function () {
            data["questions"]["q"+counter]["answers"]["answer"+answer_counter] = $(this).html();
            answer_counter++;
        });
        counter++;
    });
    //console.log(data);
    window.location.href = '/wp-json/ac/public/getUBPrint/?data=' + encodeURIComponent(JSON.stringify(data));
 });

 $(document).on('click','[data-binding="ac_spastik_selbsttest_submit"]', function() {
    var resultValue = 0;
    var unchecked = 0;
    var exit = false;
    $(".quiz-question.required").each(function () {
        unchecked = 0;
        $(this).find(".radio").each(function () {
            if (!$(this).is(':checked')) {
               unchecked++;
            }
            if (unchecked > 1) {
                $('.error-message').fadeIn();
                console.log("eeehmm");
                exit = true;
                return false;
            }
        });
    });
    if (exit == true) {
        return false;
    }
    $(".quiz-question .radio:checked").each(function () {      
      //console.log("q"+counter +" " +$(this).val());
      resultValue += $(this).val();
    });

    if (resultValue == 0) {
        $(".result-content").attr("data-stage", "1")
        //GTM_Data_forms.formResult = "Keine Symptome";
        $(".result-headline").html("Sie haben alle Fragen mit NEIN beantwortet.");
        $(".quiz-pre-content").html('');
        $(".result-content").html("Bitte beobachten Sie Ihren Krankheitsverlauf aufmerksam und wenden sich an Ihren Arzt, wenn Sie Veränderungen bemerken.");
    }
    else if (resultValue > 0) {
        $(".result-content").attr("data-stage", "2")
        //GTM_Data_forms.formResult = "Symptome";
        $(".result-headline").html("Sie haben mindestens eine der Fragen mit JA beantwortet.");
        $(".quiz-pre-content").html('');
        $(".result-content").html("Bitte wenden Sie sich an Ihren Arzt und nehmen bestenfalls Ihre Checkliste mit. Ihr Arzt wird Sie zur weiteren Abklärung der Beschwerden ggf. an einen Spezialisten, ein lokales Schlaganfallzentrum oder an einen geeigneten Therapeuten überweisen.");
    }
    $(".result-disclaimer").html('Diese Checkliste folgt der von der World Stroke Organisation entwickelten Post Stroke Checklist und wurde von Abbvie für die Zwecke der vorliegenden Materialien angepasst. Die Original-Checkliste finden Sie <a href="https://www.world-stroke.org/assets/downloads/Post-Stroke-Checklist-PSC.png" target="_blank">hier</a>');
    $('[data-binding="ac_spastik_selbsttest_print"]').css("display", "inline-block");
    $("#quiz-form").fadeOut();
    if ($(window).width() < 769) {
        var offset = 110;
    }
    else{
        var offset = 220;
    }
    $('html,body').animate({scrollTop: $('[name="selbsttest-anchor"]').offset().top - offset}, 'slow');

    //GTM Form Tracking
    // var GTM_Data_forms = { "event": "formSubmit", "formName":"Spastik nach Schlaganfall Selbsttest Form"};
    // GTM_Data_forms.formStep = "Form Submit";
    // GTM_Data_forms.formStepNr = "2";
    // GTM_Data_forms.formStepCount = "3";
    // publishToGoogle(JSON.parse(JSON.stringify(GTM_Data_forms)));
    // console.log("formSubmit tracked for: " + GTM_Data_forms.formResult);

});

$(document).on('click','.quiz-question.required .radio', function() {
    var parentAttr = $(this).closest("[data-parent]").attr("data-parent");
   if (parentAttr) {
       if ($(this).attr("value") == 1) {
        $(".quiz-question[data-child='" + parentAttr + "']").each(function () {
            $(this).addClass("required");
        });
       }
       if ($(this).attr("value") == 0) {
        $(".quiz-question[data-child='" + parentAttr + "']").each(function () {
            $(this).removeClass("required");
        });
       }
   }
});

$(document).on('click','[data-binding="ac_spastik_selbsttest_print"]', function() {

    var data = {info: {}, questions: {}};
    var counter = 0;
    data["info"]["result-headline"] = $(".result-headline").html();
    data["info"]["result-text"] = $(".result-content").html();
    data["info"]["result-disclaimer"] = 'Diese Checkliste folgt der von der World Stroke Organisation entwickelten Post Stroke Checklist und wurde von Abbvie für die Zwecke der vorliegenden Materialien angepasst. Die Original-Checkliste finden Sie unter https://www.world-stroke.org/assets/downloads/Post-Stroke-Checklist-PSC.png';
    data["info"]["stage"] = $(".result-content").attr("data-stage");
    $(".quiz-question.required").each(function () {
        data["questions"]["q"+counter] = {};
        data["questions"]["q"+counter]["question"] = $(this).find(".quiz-question-headline").html();
        data["questions"]["q"+counter]["question-title"] = $(this).find(".quiz-question-pre-headline").html();
        data["questions"]["q"+counter]["answer"] = $(this).find("input:checked+label").html();
        counter++;
    });
    //console.log(data);
    window.location.href = '/wp-json/ac/public/getSpastikPrint/?data=' + encodeURIComponent(JSON.stringify(data));
});

//rinvoq registration
$(document).on('click', '[data-form] input[type="submit"]', function (event) {

    event.preventDefault();

    var form = $(this).parents('form').attr('data-form');
    var $request;

    $request = {
        'authCode': $('[data-form="' + form + '"]').find('[name="activation-code"]').val(),
      };
    if ($request.authCode === '') {
        $('[data-form="' + form + '"]').find('.empty-field-warning').css("display", "block");
        return false;
    }

    sendAC2Request($request, 'registrationValidateAuthCodeRequest', form);

});

scrollPosition = 0;
$('.submenu_ul').scroll(function() {
    //horizontal sscroll position fix variable for submenu_stick()
    scrollPosition = $(this).scrollLeft();
    if ($(this).scrollLeft() > 0) {
        $('.submenu_outer .arrow.left').addClass("visible");
    }
    else{
        $('.submenu_outer .arrow.left').removeClass("visible");
    }
    if ($(this).scrollLeft() == ($(this)[0].scrollWidth - $(this).width())) {
        $('.submenu_outer .arrow.right').removeClass("visible");
    }
    else{
        $('.submenu_outer .arrow.right').addClass("visible");
    }
});

$(document).on('click', '.submenu_outer .arrow.right', function () {
    $('.submenu_ul').animate({scrollLeft: "+=185"}, 'slow');
});
$(document).on('click', '.submenu_outer .arrow.left', function () {
    $('.submenu_ul').animate({scrollLeft: "+=-185"}, 'slow');
});


$( window ).on( "resize", function() {
    back_to_top_arrow_right();
} );

$( window ).on( "resize load", function() {
    // if ($(".header-swiper").hasClass("mobile_or_tablet") && $(".grey-overlay, .white-overlay").length > 0) {
    //     var navHeight = 0;
    //     var submenu = 0;
    //     // if ($('.nav-container').length) {
    //     //     navHeight = $('.nav-container').height();
    //     // }
    //     // if ($('.submenu_ul').length) {
    //     //     submenu = $('.submenu_ul').height();
    //     // }
    //     //weird microsite too wide fix
    //     height = $(window).height() - (navHeight + submenu);
    //     //$(".header-swiper-text-wrapper").css("height", height+1  + "px");
    //     $('.swiper-slide img').each(function () {
    //         $(this).css("height", height+1  + "px");
    //     });
    // }
    //make submenu scrollable
    if ($('.submenu_ul').length && $('.submenu_ul')[0].scrollWidth - $(this).width()) {
        $('.submenu_outer').addClass("scroll-active");
    }
    
} );


$(document).on('click', '.back-to-top', function (event) {

    $('html,body').animate({scrollTop: $('.vc_the_content--content').offset().top-$(".vc_submenu").height()}, 'slow');

});



$(document).scroll(function () {
    
    show_back_to_top_arrow();
});

